import store from './store/store';

import { Toaster } from 'react-hot-toast';
import RoutesDefinitionNew from 'ui/design-base/RoutesDefinition';
import LoadCSS from 'helpers/LoadCSS';

import WrapNewUi from 'helpers/WrapNewUi';
import { Provider } from 'react-redux';

export const App: React.FC = () => (
  <Provider store={store}>
    <Toaster
      position='top-center'
      containerStyle={{
        marginTop: 'env(safe-area-inset-bottom)', // iOS safe area
      }}
    />

    <WrapNewUi>
      <RoutesDefinitionNew />
    </WrapNewUi>

    <LoadCSS />
  </Provider>
);

export default App;
