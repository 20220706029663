import { useEffect, useState } from 'react';
import type { AppState } from '@capacitor/app';
import { App } from '@capacitor/app';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { useAppDispatch } from 'store/store';
import { clearCacheMap } from 'api';
import { useNavigate } from 'react-router-dom';

const LOCALSTORAGE_KEY = `${import.meta.env.VITE_ENV || 'development'}_tennistalker_app_state`;

const useAppLifecycle = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate(); // Get the navigate function
  const [shouldNavigateHome, setShouldNavigateHome] = useState(false); // State to trigger navigation

  useEffect(() => {
    const handleAppStateChange = (state: AppState) => {
      if (!state.isActive) {
        try {
          localStorage.setItem(LOCALSTORAGE_KEY, Date.now().toString());
        } catch (error) {
          console.error('Error in handleAppStateChange (inactive):', error);
        }
      } else {
        try {
          clearCacheMap();
          const storedTime = localStorage.getItem(LOCALSTORAGE_KEY);
          if (storedTime) {
            const lastClosed = parseInt(storedTime, 10);
            const now = Date.now();
            const elapsedMinutes = (now - lastClosed) / 60000;
            console.warn(`App was closed for ${elapsedMinutes.toFixed(2)} minutes`);
            if (elapsedMinutes > 30) {
              setShouldNavigateHome(true); // Set state to trigger navigation
            }
          }
        } catch (error) {
          console.error('Error in handleAppStateChange (active):', error);
        }
      }
    };
    let resumeListener: PluginListenerHandle | null = null;

    const setupListeners = async () => {
      try {
        resumeListener = await App.addListener('appStateChange', handleAppStateChange);
      } catch (error) {
        console.error('Error setting up app state change listener:', error);
      }
    };

    if (Capacitor.isNativePlatform()) {
      setupListeners();
    }

    return () => {
      resumeListener?.remove();
    };
  }, [dispatch]);

  useEffect(() => {
    if (shouldNavigateHome) {
      navigate('/'); // Navigate to the home page
      setShouldNavigateHome(false); // Reset the state
    }
  }, [shouldNavigateHome, navigate]);

  useEffect(() => {
    const requestTrackingPermission = async () => {
      try {
        const status = await AppTrackingTransparency.requestPermission();
        console.warn('Tracking status:', status);

        if (status.status === 'authorized') {
          console.warn('Tracking authorized');
          // You can now track the user
        } else {
          console.warn('Tracking not authorized');
        }
      } catch (error) {
        console.error('Error requesting tracking permission:', error);
      }
    };

    if (Capacitor.isNativePlatform()) {
      App.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          App.exitApp(); // Close app if there's no back history
        } else {
          window.history.back();
        }
      });

      requestTrackingPermission();
    }
  }, []);
};

export default useAppLifecycle;
