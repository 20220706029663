import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import type { DrawCallType, DrawType } from 'types/draw';

export const fetchDraw = createAsyncThunk<DrawType | null, string | number | undefined>(
  'draws/fetchDraw',
  async (id, { rejectWithValue }) => {
    if (id) {
      const response = await callTk<DrawType>(
        `draws/${id}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return null;
  },
);

export const deleteMatches = createAsyncThunk<DrawCallType | null, string | number | undefined>(
  'draws/deleteMatches',
  async (id, { rejectWithValue }) => {
    if (id) {
      const response = await callTk<DrawCallType>(`draws/${id}/delete-matches`, { method: 'PUT' }, (error) =>
        rejectWithValue(error),
      );
      return response;
    }
    return null;
  },
);

export const deleteDraw = createAsyncThunk<{ success: boolean } | null, string | number | undefined>(
  'draws/deleteDraw',
  async (id, { rejectWithValue }) => {
    if (id) {
      const response = await callTk<{ success: boolean }>(`draws/${id}`, { method: 'DELETE' }, (error) =>
        rejectWithValue(error),
      );
      return response;
    }
    return null;
  },
);

export const drawSlice = createSlice({
  name: 'draw',
  initialState: {
    error: false,
    loading: false,
    draw: null as DrawType | null,
  },
  reducers: {
    updateMatchResult: (state, action: PayloadAction<{ matchId: number; result: string }>) => {
      const { matchId, result } = action.payload;
      const match = state.draw?.matches?.find((m) => m.id === matchId);
      if (match) {
        match.result = result;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDraw.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchDraw.fulfilled, (state, action: PayloadAction<DrawType | null>) => {
      state.loading = false;
      state.draw = action.payload;
    });
    builder.addCase(fetchDraw.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(deleteMatches.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteMatches.fulfilled, (state, action: PayloadAction<DrawCallType | null>) => {
      state.loading = false;
      if (action.payload?.draw) {
        state.draw = action.payload.draw;
        successToastMessage('Partite cancellate con successo.');
      }
    });
    builder.addCase(deleteMatches.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Errore nella cancellazione partite.');
    });
    builder.addCase(deleteDraw.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteDraw.fulfilled, (state, action: PayloadAction<{ success: boolean } | null>) => {
      state.loading = false;
      if (action.payload?.success) {
        successToastMessage('Tabellone cancellato con successo.');

        window.history.back();
      }
    });
    builder.addCase(deleteDraw.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Errore nella cancellazione tabellone.');
    });
  },
});

export const { updateMatchResult } = drawSlice.actions;
export const drawReducer = drawSlice.reducer;
