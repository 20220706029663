import 'whatwg-fetch';

import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import packageInfo from '../package.json';

import type { AppDispatch } from './store/store';
import store from './store/store';

import { isLoggedIn, getFingerPrint } from 'helpers/authentication';

import { clearCacheMap } from 'api';
import { isWebPSupported } from 'helpers/utils';
import { fetchNotificationsCounter } from 'store/notifications/notificationsSlice';
import { setUserId } from 'ui/design-base/Analytics';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { TextZoom } from '@capacitor/text-zoom';
import App from 'App';

const FIVE_MINUTES = 5 * 60 * 1000;
getFingerPrint();
isWebPSupported();

const fetchNotifications = () => {
  if (isLoggedIn()) {
    const dispatch: AppDispatch = store.dispatch;
    dispatch(fetchNotificationsCounter());
  }
};
fetchNotifications();
setInterval(fetchNotifications, FIVE_MINUTES);

if (isLoggedIn()) setUserId();

setInterval(() => {
  clearCacheMap();
}, 12 * FIVE_MINUTES);

const { version } = packageInfo;
//eslint-disable-next-line
console.log(`Running tennistalker version: ${version}`);

if (Capacitor.isNativePlatform()) {
  SplashScreen.hide();
  TextZoom.set({ value: 1.0 }); // Force text zoom to 100%
}

const root = createRoot(document.getElementById('root') as HTMLDivElement);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
